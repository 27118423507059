import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { BundlePB, BundlesConfig, PageContext } from "../../../components/slices/types";
import ProductFilterBox from "./product-filter-box";
import ProductFilterIntakeBox from "./product-filter-intake-box";
import { useAppSelector } from "../../../app/hooks";
import ProductFormWithFilter from "../../../components/sections/product-page/product/product-form-with-filter";

const CategoryTitle = styled.h2`
    color: black;
    margin: 0 0 16px 0;
`;

const Categories = styled.ul`
    list-style: none;
    padding: 0;
    display: flex;
    margin: 0 0 48px;
`;

const Category = styled.li`
    list-style: none;
    padding: 0;
    margin: 0 8px 0 0;
`;

const CategoryLabel = styled.label<{ $selected: boolean }>`
    padding: 4px 12px;
    border: 1px solid black;
    border-radius: 16px;
    cursor: pointer;

    ${({ $selected }) => $selected ? `
        background-color: #06754A;
        border-color: #06754A;

        span {
            color: white;
        }
    ` : `
        span {
            color: black;
        }
    `}
`;

const CategoryInput = styled.input`
    display: none;
`;

const TagTitle = styled.h3<{ $allowed: boolean }>`
    display: block;
    color: white;
    margin: 0 0 4px 0;
    padding: 4px 0 4px 8px;
    // background-color: ${({ $allowed }) => $allowed ? 'green' : 'red'};
    background-color: black;
`;

const TagLists = styled.div`
    border: 1px solid black;
    margin: 0 0 24px;
    padding: 0 0 16px;
`;

const TagCategoryTitle = styled.h3`
    color: black;
    margin: 0 0 4px 8px;    
`;

const TagCategories = styled.ul`
    list-style: none;
    padding: 0;
    display: flex;
    margin: 0 0 8px;
    flex-wrap: wrap
`;

const TagCategory = styled.li`
    list-style: none;
    padding: 0;
    margin: 0 8px 8px 8px;
`;

const TagCategoryLabel = styled.label<{ $selected: boolean, $allowed: boolean }>`
    display: flex;
    padding: 4px 8px;
    border: 1px solid black;
    border-radius: 16px;
    cursor: pointer;
    min-width: 16px;
    justify-content: space-around;

    ${({ $selected, $allowed }) => $selected ? `
        background-color: ${$allowed ? 'green' : 'red'};
        border-color: ${$allowed ? 'green' : 'red'};
        color: white;
        
        span {
            color: white;
        }
    ` : `
        span {
            color: black;
        }
    `}
`;

const ColorBubble = styled.div<{ $color: string }>`
    width: 8px;
    height: 12px;
    padding: 2px 4px;
    margin-left: 8px;
    border: 2px solid white;
    border-radius: 50%;
    background-color: ${({ $color }) => $color}
`;

const TagCategoryInput = styled.input`
    display: none;
`;

const List = styled.ul`
    list-style: none;
    padding: 0;
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;
`;

const GridContainer = styled.div`
`;

interface GodViewProductConfiguratorProps {
    bundles?: PageContext['bundles'];
    translations: PageContext['translations'];
}
type PreselectType = 'subscription' | 'one-time';
const PRESELECT_INDICES: number[] = [1, 2, 3, 4, 5, 6];
const PRESELECT_TYPES: PreselectType[] = ['subscription', 'one-time'];

const ProductConfigurator = (props: GodViewProductConfiguratorProps): JSX.Element => {
    const { bundles, translations } = props;
    const language = useAppSelector((state) => state.cart.language);
    const [config, setConfig] = useState<BundlesConfig>();
    const [selectedCategory, setSelectedCategory] = useState<string>();
    const [selectedPreselectIndex, setSelectedPreselectIndex] = useState<number>(PRESELECT_INDICES[0]);
    const [selectedPreselectType, setSelectedPreselectType] = useState<PreselectType>(PRESELECT_TYPES[0]);
    const [selectedAllowedTags, setSelectedAllowedTags] = useState<string[]>([]);
    const [selectedDisallowedTags, setSelectedDisallowedTags] = useState<string[]>([]);
    const [filterString, setFilterString] = useState<string>('');
    const lang = language.toLowerCase();

    const tagCategories = !selectedCategory ? null : config?.product_categories.find((pc) => pc.category === selectedCategory)?.tag_categories;

    const updateAllowedTag = (tag: string, unique: boolean) => {
        if (selectedAllowedTags.includes(tag)) {
            setSelectedAllowedTags(selectedAllowedTags.filter( oldTag => oldTag !== tag))
        } else {
            if (unique) {
                const tagCategory = tagCategories?.find((category) => category.tags.includes(tag));
                const filteredTags = selectedAllowedTags.filter((selectedTag) => !tagCategory?.tags.includes(selectedTag))

                setSelectedAllowedTags([...filteredTags, tag])
            } else {
                setSelectedAllowedTags([...selectedAllowedTags, tag])
            }

            if (selectedDisallowedTags.includes(tag)) {
                setSelectedDisallowedTags(selectedDisallowedTags.filter( oldTag => oldTag !== tag))
            }
        }
    }

    const updateDisallowedTag = (tag: string) => {
        if (selectedDisallowedTags.includes(tag)) {
            setSelectedDisallowedTags(selectedDisallowedTags.filter( oldTag => oldTag !== tag));
        } else {
            setSelectedDisallowedTags([...selectedDisallowedTags, tag]);

            if (selectedAllowedTags.includes(tag)) {
                setSelectedAllowedTags(selectedAllowedTags.filter( oldTag => oldTag !== tag))
            }
        }
    }

    useEffect(() => {
        if (!selectedCategory) return;

        const tagCategories = config?.product_categories.find((pc) => pc.category === selectedCategory)?.tag_categories;
        const allTagsFromCategories = tagCategories?.reduce<string[]>((allTags, tagCategory) => {
            return [...allTags, ...tagCategory.tags];
        }, [])

        const filterObject = {
            product: selectedCategory,
            preselect_index: selectedPreselectIndex,
            preselect_type: selectedPreselectType,
            allowed_tags: selectedAllowedTags.filter((tag) => allTagsFromCategories?.includes(tag)),
            disallowed_tags: selectedDisallowedTags.filter((tag) => allTagsFromCategories?.includes(tag)),
        };

        setFilterString(JSON.stringify(filterObject));
        filterToSearch(filterObject);
    }, [selectedPreselectIndex, selectedPreselectType, selectedCategory, selectedAllowedTags, selectedDisallowedTags])

    const filterToSearch = (filter: Record<string, unknown>) => {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('filter', JSON.stringify(filter));

        const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
        window.history.replaceState(null, '', newUrl);
    };

    const searchToFilter = () => {
        const searchParams = new URLSearchParams(window.location.search);
        
        try {
            const str = searchParams.get('filter') as string;
            JSON.parse(str);

            formatFilter(str);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetch('/bundles/config.json').then(res => res.json()).then((bundlesConfig: BundlesConfig) => {
            setConfig(bundlesConfig)
            searchToFilter()
        })
    }, [])

    const formatFilter = (str: string) => {
        const filter = JSON.parse(str);
        setSelectedCategory(filter.product);
        setSelectedPreselectIndex(filter.preselect_index);
        setSelectedPreselectType(filter.preselect_type);
        setSelectedAllowedTags(filter.allowed_tags);
        setSelectedDisallowedTags(filter.disallowed_tags);
    }

    return (
        <>
            <ProductFilterIntakeBox
                title={'Enter product filter: '}
                onFormat={formatFilter}
            />
            <ProductFilterBox
                filterString={filterString}
            />
            <Categories>
                {config && config.product_categories.map(({ category, tag_categories }) => {
                    return (
                        <Category key={category}>
                            <CategoryLabel $selected={selectedCategory === category}>
                                <span>{ category }</span>
                                <CategoryInput type="checkbox" onInput={() => setSelectedCategory(category)}/>
                            </CategoryLabel>
                        </Category>
                    )
                })}
            </Categories>
            <Grid>
                <GridContainer>
                    {tagCategories && (
                        <TagLists>
                            <TagTitle $allowed={true}>Preselect</TagTitle>
                            <TagCategoryTitle>Type</TagCategoryTitle>
                            <TagCategories>
                                {PRESELECT_TYPES.map((tag) => (
                                    <TagCategory key={tag}>
                                        <TagCategoryLabel $selected={selectedPreselectType === tag} $allowed={true}>
                                            <span>{tag}</span>
                                            <TagCategoryInput type="checkbox" onInput={() => setSelectedPreselectType(tag)}/>
                                        </TagCategoryLabel>
                                    </TagCategory>
                                ))}
                            </TagCategories>
                            <TagCategoryTitle>Index</TagCategoryTitle>
                            <TagCategories>
                                {PRESELECT_INDICES.map((tag) => (
                                    <TagCategory key={tag}>
                                        <TagCategoryLabel $selected={selectedPreselectIndex === tag} $allowed={true}>
                                            <span>{tag}</span>
                                            <TagCategoryInput type="checkbox" onInput={() => setSelectedPreselectIndex(tag)}/>
                                        </TagCategoryLabel>
                                    </TagCategory>
                                ))}
                            </TagCategories>
                        </TagLists>
                    )}
                    {tagCategories && (
                        <TagLists>
                            <TagTitle $allowed={true}>Allowed tags</TagTitle>
                            {tagCategories?.map(({ category, tags, unique }) => (
                                <React.Fragment key={category}>
                                    <TagCategoryTitle>{category}</TagCategoryTitle>
                                    <TagCategories>
                                        {tags.map((tag) => (
                                            <TagCategory key={tag}>
                                                <TagCategoryLabel $selected={selectedAllowedTags.includes(tag)} $allowed={true}>
                                                    <span>{ category === 'colors' ? tag : config?.translations[`${tag}_${lang}`] }</span>
                                                    <TagCategoryInput type="checkbox" onInput={() => updateAllowedTag(tag, !!unique)}/>
                                                    {category === 'colors' ? <ColorBubble $color={config?.translations[`${tag}_hex`]}/> : null}
                                                </TagCategoryLabel>
                                            </TagCategory>
                                        ))}
                                    </TagCategories>
                                </React.Fragment>
                            ))}
                        </TagLists>
                    )}
                    {tagCategories && (
                        <TagLists>
                            <TagTitle $allowed={false}>Disallowed tags</TagTitle>
                            {tagCategories?.map(({ category, tags }) => (
                                <React.Fragment key={category}>
                                    <TagCategoryTitle>{category}</TagCategoryTitle>
                                    <TagCategories>
                                        {tags.map((tag) => (
                                            <TagCategory key={tag}>
                                                <TagCategoryLabel $selected={selectedDisallowedTags.includes(tag)} $allowed={false}>
                                                    <span>{ category === 'colors' ? tag : config?.translations[`${tag}_${lang}`] }</span>
                                                    <TagCategoryInput type="checkbox" onInput={() => updateDisallowedTag(tag)}/>
                                                    {category === 'colors' ? <ColorBubble $color={config?.translations[`${tag}_${lang}`]}/> : null}
                                                </TagCategoryLabel>
                                            </TagCategory>
                                        ))}
                                    </TagCategories>
                                </React.Fragment>
                            ))}
                        </TagLists>
                    )}
                </GridContainer>
                <GridContainer>
                    {tagCategories && (
                        <ProductFormWithFilter
                            filterString={filterString}
                            translations={translations}
                            location={location as unknown as Record<string, unknown>}
                            // settings={settings}
                            shouldShowCompareBrushes={false}
                            showFloatingSelector={false}
                        />
                    )}
                </GridContainer>
            </Grid>
        </>
    )
}

export default ProductConfigurator;
import React from "react";
import { graphql, PageProps } from "gatsby"

import Layout from "../../components/layout/layout"
import Seo from "../../components/layout/seo"

import { PageContext } from "../../components/slices/types"
import { useAppSelector } from "../../app/hooks"
import LanguageSelect from "../../components/snippets/language/language-select/language-select";
import styled from "styled-components";
import ProductConfigurator from "./snippets/product-configurator";

const Overview = styled.section`
    padding: 200px 0 16px;
    // background-color: #06754A;
`;

const Wrapper = styled.div`
    max-width: 1440px;
    margin: 0 auto;
`;

const Header = styled.header`
    display: grid;
    grid-template-columns: 1fr auto;
    padding: 16px 8px 40px;
    
    * {
        color: white;
    }
`;

const Heading = styled.h1`
    color: var(--black-color);
`;

const Body = styled.div`
    padding: 0 8px;
`;

const Footer = styled.footer`

`;
const ProductConfiguratorPage = (props: PageProps<Queries.ProductConfiguratorPageQuery, PageContext>): JSX.Element => {
    const globalAudience = useAppSelector(state => state.general.audience);
    const { data, location, pageContext } = props;
    const { allPrismicProductPage, allPrismicHeader, prismicFooter, allPrismicProductVariant } = data;
    let headerData = allPrismicHeader.nodes.find(node => node.tags.includes((globalAudience || audience || 'kids')))?.data;
    headerData = headerData ?? allPrismicHeader.nodes[0]?.data;
    const { data: footerData } = prismicFooter || {};
    const { audience, translations, settings, lang, bundles } = pageContext;

    const products = allPrismicProductPage.nodes.map((node) => {
        // @ts-ignore
        const data = node.data?.prismic_product?.document?.data;
        if (data) data.id = node.data?.prismic_product?.id;
        if (data) data.url = node.url;

        return data
    });

    return (
        <Layout
            bundles={[]}
            footerData={footerData as Queries.PrismicFooterDataType}
            headerData={headerData as Queries.PrismicHeaderDataType}
            location={location}
            audience={audience}
            page={'God View'}
            lang={lang as string}
            translations={translations}
            settings={settings}
        >
            <Seo title={'Product configurator'} description={'Product configurator'} lang={lang?.split('-')[0]} canonicalUrl={`https://playbrush.com${location.pathname}`} />
            <Overview>
                <Wrapper>
                    <Header>
                        <Heading>Product configurator</Heading>
                        <LanguageSelect location={location} />
                    </Header>
                    <Body>
                        <ProductConfigurator
                            bundles={bundles}
                            translations={translations}
                        />
                    </Body>
                    <Footer>

                    </Footer>
                </Wrapper>
            </Overview>
        </Layout>
    )
}

export const query = graphql`
    query ProductConfiguratorPage($lang: String) {
        allPrismicProductPage(filter: {lang: {eq: $lang}}) {
            nodes {
                url
                data {
                    prismic_product {
                    id
                    document {
                        ... on PrismicProduct {
                            _previewable
                                id
                                data {
                                    variant {
                                        product_variant {
                                            document {
                                                ... on PrismicProductVariant {
                                                    _previewable
                                                    id
                                                    data {
                                                        stripe_variant {
                                                            _id
                                                            active
                                                            default_price
                                                            price_amount
                                                            price_original
                                                            price_shipping_eur
                                                            name
                                                            image
                                                            description
                                                            id
                                                            tags
                                                            metadata {
                                                                tags
                                                            }
                                                        }
                                                        image {
                                                            variant_image {
                                                                gatsbyImageData
                                                                alt
                                                            }
                                                        }
                                                        color
                                                        brush_type
                                                        size
                                                        bundles {
                                                            bundle {
                                                                document {
                                                                    ... on PrismicBundle {
                                                                        id
                                                                        data {
                                                                            description {
                                                                                richText
                                                                                text
                                                                            }
                                                                            images {
                                                                                image {
                                                                                    gatsbyImageData
                                                                                    alt
                                                                                }
                                                                            }
                                                                            title
                                                                            tags {
                                                                                border_color
                                                                                tag
                                                                            }
                                                                            manual_bundle_items {
                                                                                thumbnail_image {
                                                                                    gatsbyImageData
                                                                                    alt
                                                                                }
                                                                                quantity
                                                                                bundle_item_title
                                                                                bundle_item_hover_text
                                                                            }
                                                                            bundle {
                                                                                _id
                                                                                price_amount
                                                                                price_amount_after_trial
                                                                                price_original
                                                                                price_amount_one_time_crossed_eur
                                                                                price_amount_one_time_crossed_gbp
                                                                                price_amount_one_time_crossed_usd
                                                                                price_amount_one_time_current_eur
                                                                                price_amount_one_time_current_gbp
                                                                                price_amount_one_time_current_usd
                                                                                price_amount_pay_after_trial_crossed_eur
                                                                                price_amount_pay_after_trial_crossed_gbp
                                                                                price_amount_pay_after_trial_crossed_usd
                                                                                price_amount_pay_after_trial_current_eur
                                                                                price_amount_pay_after_trial_current_gbp
                                                                                price_amount_pay_after_trial_current_usd
                                                                                price_amount_pay_now_crossed_eur
                                                                                price_amount_pay_now_crossed_gbp
                                                                                price_amount_pay_now_crossed_usd
                                                                                price_amount_pay_now_current_eur
                                                                                price_amount_pay_now_current_gbp
                                                                                price_amount_pay_now_current_usd
                                                                                price_amount_recurring_crossed_eur
                                                                                price_amount_recurring_crossed_gbp
                                                                                price_amount_recurring_crossed_usd
                                                                                price_amount_recurring_current_eur
                                                                                price_amount_recurring_current_gbp
                                                                                price_amount_recurring_current_usd
                                                                                recurring_interval_count_eur
                                                                                recurring_interval_count_gbp
                                                                                recurring_interval_count_usd
                                                                                recurring_trial_period_days_gbp
                                                                                recurring_interval_eur
                                                                                recurring_interval_gbp
                                                                                recurring_interval_usd
                                                                                recurring_trial_period_days_eur
                                                                                recurring_trial_period_days_usd
                                                                                savings_eur
                                                                                savings_gbp
                                                                                savings_usd
                                                                                items_extended {
                                                                                    unit_amount
                                                                                    recurring_trial_period_days
                                                                                    type
                                                                                    recurring_interval_count
                                                                                    recurring_interval
                                                                                    product_blob {
                                                                                        tags
                                                                                        price_recurring_trial_period_days
                                                                                        price_recurring_interval_count
                                                                                        price_recurring_interval
                                                                                        price_original_after_trial
                                                                                        price_original
                                                                                        price_amount_after_trial
                                                                                        price_amount
                                                                                        name
                                                                                        default_price
                                                                                        image
                                                                                        description
                                                                                        active
                                                                                        metadata {
                                                                                            prod_image_corner_note_EN
                                                                                            prod_image_title_DE
                                                                                            prod_image_title_EN
                                                                                            prod_image_tooltip_text_DE
                                                                                            prod_image_tooltip_text_EN
                                                                                            prod_marketing_title_DE
                                                                                            prod_marketing_title_EN
                                                                                            prod_order_title_DE
                                                                                            prod_order_title_EN
                                                                                            tags
                                                                                            prod_description_DE
                                                                                            prod_description_EN
                                                                                            prod_image_corner_note_DE
                                                                                        }
                                                                                        _id
                                                                                        description_de
                                                                                        description_en
                                                                                    }
                                                                                    product
                                                                                    price_default_blob {
                                                                                        active
                                                                                        currency
                                                                                        _id
                                                                                        product
                                                                                        recurring_interval
                                                                                        recurring_interval_count
                                                                                        recurring_trial_period_days
                                                                                        type
                                                                                        unit_amount
                                                                                    }
                                                                                    currency
                                                                                    active
                                                                                    _id
                                                                                }
                                                                                savings
                                                                                tags
                                                                                name_order_EN
                                                                                name_order_DE
                                                                                name_marketing_EN
                                                                                name_marketing_DE
                                                                                name
                                                                                image
                                                                                id
                                                                                currency
                                                                                recurring_interval
                                                                                recurring_trial_period_days
                                                                                price_amount_one_time
                                                                                price_amount_recurring
                                                                                price_original_after_trial
                                                                                price_original_one_time
                                                                                price_original_recurring
                                                                                price_shipping_eur
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    star_rating
                                    is_bundle
                                    description {
                                        richText
                                        text
                                    }
                                    tags {
                                        border_color
                                        tag
                                    }
                                    title {
                                        richText
                                        text
                                    }
                                    images {
                                        image {
                                        gatsbyImageData
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        allPrismicProductVariant(filter: {lang: {eq: $lang}}) {
            nodes {
                id
                data {
                    stripe_variant {
                        default_price
                    }
                    bundles {
                        bundle {
                            document {
                                ... on PrismicBundle {
                                    id
                                    data {
                                        description {
                                            richText
                                        }
                                        title
                                        tags {
                                            border_color
                                            tag
                                        }
                                        bundle {
                                            _id
                                            price_amount
                                            price_amount_after_trial
                                            price_original
                                            price_amount_one_time_crossed_eur
                                            price_amount_one_time_crossed_gbp
                                            price_amount_one_time_crossed_usd
                                            price_amount_one_time_current_eur
                                            price_amount_one_time_current_gbp
                                            price_amount_one_time_current_usd
                                            price_amount_pay_after_trial_crossed_eur
                                            price_amount_pay_after_trial_crossed_gbp
                                            price_amount_pay_after_trial_crossed_usd
                                            price_amount_pay_after_trial_current_eur
                                            price_amount_pay_after_trial_current_gbp
                                            price_amount_pay_after_trial_current_usd
                                            price_amount_pay_now_crossed_eur
                                            price_amount_pay_now_crossed_gbp
                                            price_amount_pay_now_crossed_usd
                                            price_amount_pay_now_current_eur
                                            price_amount_pay_now_current_gbp
                                            price_amount_pay_now_current_usd
                                            price_amount_recurring_crossed_eur
                                            price_amount_recurring_crossed_gbp
                                            price_amount_recurring_crossed_usd
                                            price_amount_recurring_current_eur
                                            price_amount_recurring_current_gbp
                                            price_amount_recurring_current_usd
                                            recurring_interval_count_eur
                                            recurring_interval_count_gbp
                                            recurring_interval_count_usd
                                            recurring_trial_period_days_gbp
                                            recurring_interval_eur
                                            recurring_interval_gbp
                                            recurring_interval_usd
                                            recurring_trial_period_days_eur
                                            recurring_trial_period_days_usd
                                            savings_eur
                                            savings_gbp
                                            savings_usd
                                            items_extended {
                                                unit_amount
                                                recurring_trial_period_days
                                                type
                                                recurring_interval_count
                                                recurring_interval
                                                product_blob {
                                                    tags
                                                    price_recurring_trial_period_days
                                                    price_recurring_interval_count
                                                    price_recurring_interval
                                                    price_original_after_trial
                                                    price_original
                                                    price_amount_after_trial
                                                    price_amount
                                                    name
                                                    default_price
                                                    image
                                                    description
                                                    active
                                                    metadata {
                                                        prod_image_corner_note_EN
                                                        prod_image_title_DE
                                                        prod_image_title_EN
                                                        prod_image_tooltip_text_DE
                                                        prod_image_tooltip_text_EN
                                                        prod_marketing_title_DE
                                                        prod_marketing_title_EN
                                                        prod_order_title_DE
                                                        prod_order_title_EN
                                                        tags
                                                        prod_description_DE
                                                        prod_description_EN
                                                        prod_image_corner_note_DE
                                                    }
                                                    _id
                                                    description_de
                                                    description_en
                                                }
                                                product
                                                price_default_blob {
                                                    active
                                                    currency
                                                    _id
                                                    product
                                                    recurring_interval
                                                    recurring_interval_count
                                                    recurring_trial_period_days
                                                    type
                                                    unit_amount
                                                }
                                                currency
                                                active
                                                _id
                                            }
                                            savings
                                            tags
                                            name_order_EN
                                            name_order_DE
                                            name_marketing_EN
                                            name_marketing_DE
                                            name
                                            image
                                            id
                                            currency
                                            price_amount_one_time
                                            price_amount_recurring
                                            price_original_after_trial
                                            price_original_one_time
                                            price_original_recurring
                                            price_shipping_eur
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        allPrismicHeader(filter: {lang: {eq: $lang}}) {
            nodes {
                _previewable
                tags
                data {
                    announcement {
                        richText
                    }
                    bundle_preselect_index
                    announcement_link {
                        url
                        target
                        document {
                            ... on PrismicProductPage {
                                id
                                data {
                                    prismic_product {
                                        document {
                                            ... on PrismicProduct {
                                                id
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    menu_items {
                        link {
                            url
                            id
                            target
                            document {
                                ... on PrismicProductPage {
                                    id
                                    data {
                                        prismic_product {
                                            document {
                                                ... on PrismicProduct {
                                                    id
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        link_to_section
                        menu_bundle_preselect_index
                        link_text
                        mega_menu_items {
                            document {
                                ... on PrismicHeaderMenuItem {
                                    id
                                    data {
                                        button_text
                                        button_url {
                                            url
                                            document {
                                                ... on PrismicProductPage {
                                                    id
                                                    data {
                                                        prismic_product {
                                                            document {
                                                                ... on PrismicProduct {
                                                                    id
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        link_to_section
                                        button_bundle_preselect_index
                                        description {
                                            richText
                                        }
                                        title {
                                            richText
                                        }
                                        item {
                                            item_description {
                                                richText
                                            }
                                            item_title {
                                                richText
                                            }
                                            mega_menu_image {
                                                alt
                                                gatsbyImageData
                                            }
                                            link {
                                                url
                                                target
                                                document {
                                                    ... on PrismicProductPage {
                                                        id
                                                        data {
                                                            prismic_product {
                                                                document {
                                                                    ... on PrismicProduct {
                                                                        id
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            link_to_section
                                            bundle_preselect_index
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        prismicFooter(lang: {eq: $lang}) {
            _previewable
            data {
                disclaimer {
                    richText
                }
                footer_columns {
                    footer_column {
                        document {
                            ... on PrismicLinkList {
                                _previewable
                                id
                                type
                                data {
                                    link_text
                                    link {
                                        url
                                    }
                                    sub_links {
                                        sub_link_text
                                        sub_link {
                                            url
                                        }
                                    }
                                }
                            }
                            ... on PrismicSubscriptionForm {
                                _previewable
                                id
                                type
                                data {
                                    button_text {
                                        richText
                                    }
                                    field_placeholder
                                    optin_error
                                    email_format_error
                                    success_message
                                    list
                                    optin {
                                        richText
                                    }
                                    tags {
                                        tag
                                    }
                                    text {
                                        richText
                                    }
                                }
                            }
                            ... on PrismicSocialList {
                                _previewable
                                id
                                type
                                data {
                                    link {
                                        url
                                    }
                                    link_text
                                    sub_links {
                                        sub_link_icon
                                        sub_link {
                                            url
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                icons {
                    icon
                    title {
                        richText
                    }
                }
                social_links {
                    social_icon
                    social_link {
                        url
                        target
                    }
                }
            }
        }
    }
`

export default ProductConfiguratorPage;
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { copyToClipboard } from "@utils";


const Box = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 1fr auto;
    margin: 0 0 32px;
`;

const Title = styled.h4`
    grid-column: 1 / 3;
`;

const Input = styled.input`
    border: 1px solid black;
    border-radius: 4px 0 0 4px;
    padding: 8px;
`;

const Button = styled.button`
    color: white;
    padding: 8px 16px;
    border: 1px solid black;
    border-left: 0px;
    background-color: #06754A;
    border-radius: 0 4px 4px 0;
    outline: 0;
    cursor: pointer;

    &:hover {
        background-color: black;
    }
`;

const Popup = styled.h5`
    position: absolute;
    top: 4px;
    right: 4px;
`;

interface ProductFilterBoxProps {
    filterString: string;
}

const ProductFilterBox = (props: ProductFilterBoxProps): JSX.Element => {
    const { filterString = '' } = props;
    const [buttonText, setButtonText] = useState('copy');
    const copy = async () => {
        await copyToClipboard(filterString);
        setButtonText('copied');

        setTimeout(() => {
            setButtonText('copy');
        }, 3000);
    }

    return (
        <Box>
            <Title>Product filter configuration: </Title>
            <Input value={filterString} readOnly={true}/>
            <Button type="button" onClick={copy}>
                {buttonText}
            </Button>
        </Box>
    )
}

export default ProductFilterBox;
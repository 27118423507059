import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { copyToClipboard } from "@utils";


const Box = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 1fr auto;
    margin: 0 0 32px;
`;

const Title = styled.h4`
    grid-column: 1 / 3;
`;

const Input = styled.input`
    border: 1px solid black;
    border-radius: 4px 0 0 4px;
    padding: 8px;
`;

const Button = styled.button`
    color: white;
    padding: 8px 16px;
    border: 1px solid black;
    border-left: 0px;
    background-color: #06754A;
    border-radius: 0 4px 4px 0;
    outline: 0;
    cursor: pointer;

    &:hover {
        background-color: black;
    }
`;

const Popup = styled.h5`
    position: absolute;
    top: 4px;
    right: 4px;
`;

interface ProductFilterIntakeBoxProps {
    title?: string;
    onFormat: (str: string) => void;
}

const ProductFilterIntakeBox = ({ title, onFormat }: ProductFilterIntakeBoxProps): JSX.Element => {
    const input = useRef(null)

    const format = (e: React.MouseEvent<HTMLElement>) => {
        try {
            // @ts-ignore
            const inputString = input?.current?.value;
            const filter = JSON.parse(inputString);

            if (filter) {
                onFormat(inputString);
            }

            // @ts-ignore
            if (input?.current?.value) input.current.value = '';
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Box>
            {title && <Title>{title}</Title>}
            <Input ref={input} />
            <Button type="button" onClick={format}>
                format
            </Button>
        </Box>
    )
}

export default ProductFilterIntakeBox;